.el-select-dropdown__item {
  height: max-content;
}

.showPlaceholder {
  /deep/ {
    .is-disabled .el-input__inner,
    .el-input__inner {
      border: 1px solid #cccfd4;
      border-radius: 10px;
    }

    .is-focus .el-input__inner,
    .el-input__inner {
      height: 70px;
      font-size: 0;
      background-position: right 10px center;
    }
    .el-input__prefix,
    .el-input__suffix {
      color: $primary-purple;
      width: 90%;
      top: 20%;
    }
    .el-input__prefix {
      .type_Img {
        float: left;
        @include rtl-sass-value(margin-left, 15px, 30px);
        margin-top: -5px;
      }
    }
    .is-disabled .el-input__prefix {
      color: #cccfd4;
    }
    .select_text {
      float: left;
      margin-top: 5px;
      @include rtl-sass-value(margin-left, 10px, 30px);
      p {
        margin-top: 10px;
        display: inline-block;
      }
      i {
        margin-left: 10px;
        font-size: 20px;
      }
    }
    .amount {
      float: right;
      margin-top: 5px;
      &.mr_amount {
        margin-top: 1px;
      }
    }
  }
}

.creditCard {
  /deep/ {
    .is-disabled .el-input__prefix {
      color: $primary-purple;
    }
  }
  &.ccReset {
    /deep/ {
      .is-disabled .el-input__prefix {
        color: #cccfd4;
      }
    }
  }
}

.type_Img {
  width: 80px;
  max-height: 50px;
  object-fit: contain;
  padding: 5px;
}

.select_text {
  float: right;
  margin-top: 10px;
  font-size: 16px;
}

@media (max-width: 1440px) {
  .showPlaceholder {
    /deep/ {
      .select_text {
        max-width: 200px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .showPlaceholder {
    /deep/ {
      .select_text {
        max-width: none;
      }
    }
  }
}

@media (max-width: 768px) {
  .showPlaceholder {
    /deep/ {
      .select_text {
        max-width: 200px;
      }
    }
  }
}

@media (max-width: 550px) {
  .showPlaceholder {
    /deep/ {
      .select_text {
        &.small_text {
          font-size: 12px;
          max-width: 100px;
        }
      }
    }
  }
}

@media (max-width: 380px) {
  .showPlaceholder {
    /deep/ {
      .el-input__prefix {
        .type_Img {
          display: none;
        }
      }
    }
  }
}
