.withDraw_form {
  margin-top: 10px;
  .box_card_mid {
    padding: 64px 32px 32px;

    .totalAmount {
      @include rtl-sass-value(text-align, right, left);
      margin: 15px 0;
    }

    .visa_logo {
      height: 29px;
    }

    .tabs {
      margin-top: 20px;

      > div input {
        display: none;
      }

      .el-row {
        .type_Img {
          margin-right: 10px;

          img {
            width: 65px;
            min-width: 65px;
          }
        }
      }

      .card_status {
        i {
          font-weight: bold;
          &.el-icon-check {
            color: #00f0ff;
          }
          &.el-icon-close {
            color: #e91545;
          }
        }
      }

      .tab {
        width: 100%;
        overflow: hidden;
        margin-bottom: 1rem;
        border: 1px solid $primary-purple;
        border-radius: 0.375rem;
        padding: 1rem;

        &-title {
          .el-col {
            display: flex;
            justify-content: center;

            .label {
              color: $primary-purple;
              @include rtl-sass-prop(margin-right, margin-left, 20px);
              @include rtl-sass-prop('', margin-right, 30px);
              font-size: 16px;
              min-width: 84px;
              line-height: 1.5;
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              align-content: center;
              display: -webkit-flex; /* Safari */
              align-items: center; /* Safari */
              word-break: keep-all;
            }

            .amount {
              border-radius: 3px;
              width: 100%;
              padding: 0 15px;
              line-height: 40px;
            }

            .arrow {
              border-width: 0 2px 2px 0;
              border-style: solid;
              width: 0.5rem;
              height: 0.5rem;
              transform: translateY(-60%) rotate(45deg);
              color: $primary-purple;
              margin-top: 20px;
            }

            &.flex_start {
              justify-content: flex-start;
            }
          }

          label {
            cursor: pointer;
          }
        }

        &-content {
          display: none;
          margin-top: 1rem;
          padding: 1rem;
          border-top: 1px solid $primary-purple;
        }

        /deep/ {
          .exp-date {
            display: flex;
            flex-direction: column;

            > label {
              align-self: baseline;
            }
          }

          .img_row {
            .card_sample {
              @include rtl-sass-value(margin, 10px 0 0 40px, 10px 40px);
            }
          }

          .required-document {
            font-size: 16px;
            line-height: 1;
            margin-bottom: 10px;
          }

          .upload-tip {
            margin-bottom: 30px;

            &.first {
              margin-bottom: 30px;
            }
          }

          .archive-action {
            margin-top: 30px;
          }

          .form_list {
            margin-top: 30px;
          }

          .month {
            width: 35%;
            @include rtl-sass-prop(margin-right, margin-left, 15px);
          }

          .year {
            width: 55%;
          }

          .doc_upload {
            margin-top: 45px;
          }

          .archiveForm {
            display: grid;
            grid-template-columns: 48% 48%;
            column-gap: 4%;
            .labelTitle {
              margin-bottom: 10px;
              label {
                color: $primary-purple;
              }
            }
            .upload-btn {
              position: absolute;
              top: 0;
            }
            .el-form-item__error {
              margin-top: 10px;
            }
          }
        }
      }
    }

    input:checked {
      ~ .tab {
        .tab-title .el-row .el-col label /deep/ .arrow {
          transform: translateY(-50%) rotate(-135deg);
        }

        .tab-content {
          display: block;
        }
      }
    }
    .expired-cc {
      margin-top: 48px;

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0;

        .hide-cc {
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .withDraw_form {
    .box_card_mid {
      .tabs {
        .tab {
          /deep/ {
            .img_row {
              .card_sample {
                margin: 10px 0 0 10px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .withDraw_form {
    .box_card_mid {
      .tabs {
        .tab {
          &-title {
            .el-col {
              justify-content: left;
            }
          }
          .arrow,
          .card_status {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
