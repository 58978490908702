.performance_fee_dialog {
  /deep/ .el-dialog__body {
    text-align: center;

    p {
      font-weight: 200;
      line-height: 1.5;
      color: $primary-purple;
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 20px;
    }
  }
}

@media (max-width: 510px) {
  .performance_fee_dialog /deep/ .el-dialog__body {
    padding: 25px 25px 25px;
  }
}
@media (max-width: 450px) {
  .performance_fee_dialog /deep/ .el-dialog__body .el-button {
    margin-bottom: 20px;
    max-width: none;
  }
}
