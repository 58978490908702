#withDraw {
  .withDraw_form {
    .box {
      background-color: #ffffff;
      box-shadow: $box-shadow;
      margin-bottom: 32px;
      /deep/ .title {
        font-size: 28px;
        font-family: 'GTWalsheimPro-Bold';
        color: $primary-purple;
      }
    }

    /deep/ .form_button {
      text-align: center;
      margin-bottom: 40px;
    }

    .box_card_top {
      padding: 50px 32px 32px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .box_card_submit {
      padding: 50px 32px;
      display: flex;
      .el-checkbox {
        margin-top: 12px;
      }
      a {
        color: #055bff;
      }
    }

    /deep/.alert {
      margin-top: 30px;
      p {
        font-size: 16px;
        color: #e91545;
        margin: 0 0 10px;
      }
      ul {
        padding-left: 15px;
        max-width: 90%;

        li {
          list-style: disc;
          padding-left: 1em;
          font-size: 16px;
          line-height: 1.44;

          &:first-child {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

/deep/ .info_box {
  padding-left: 18px;

  li {
    color: #055bff;
    margin-bottom: 10px;
    list-style: decimal;
    padding-left: 1em;

    &::marker {
      color: #055bff;
    }
  }
}

.vl {
  width: 0;
  height: 524px;
  opacity: 0.25;
  @include animation-ease(fadeInFromNone);
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 0.25;
  }
}

@media (max-width: 1200px) {
  #withDraw .withDraw_form .box_card_top .top {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  #withDraw .withDraw_form /deep/ .box_card_bottom .form_bottom_row {
    .img_row,
    .form_row {
      float: none;
    }

    .img_row {
      margin-bottom: 50px;

      img {
        margin: 0 auto;
      }
    }

    .form_row {
      max-width: 100%;
    }

    .form_list {
      grid-template-columns: 47.5% 47.5%;
      column-gap: 5%;
    }
  }
  #withDraw .withDraw_form /deep/ .box_card_bottom #visaForm .img_row {
    margin: 0 auto 40px;
    text-align: center;

    .img1 {
      display: block;
    }
  }
  #withDraw .withDraw_form .box_card_top {
    flex-direction: column;
  }
  .vl {
    width: 700px;
    height: 0px;
  }
}

@media (max-width: 768px) {
  .vl {
    width: 500px;
  }
}

@media (max-width: 550px) {
  #withDraw .withDraw_form .box_card_top {
    padding: 50px 24px 30px;
    .top {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }
    .alert {
      p {
        font-size: 14px;
        margin: 0 0 20px;
      }
      ul {
        padding-left: 30px;
        max-width: 100%;
        li {
          font-size: 12px;
        }
      }
    }
  }

  #withDraw .withDraw_form /deep/ .box_card_bottom {
    padding: 30px 24px;
    .form_bottom_row .form_list {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));

      .int_p {
        margin-bottom: 24px;
      }
    }
    #visaForm .img_row {
      text-align: left;

      .img1 {
        display: inline-block;
      }
    }
  }
  .vl {
    width: 300px;
  }

  #withDraw .withDraw_form .box_card_submit {
    flex-direction: column;
    text-align: center;
    .el-checkbox {
      margin-bottom: 12px;
    }
  }
}

@media (max-width: 370px) {
  .vl {
    width: 200px;
  }
}
