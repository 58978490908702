
              @import "@/assets/css/variables/variables.scss";
            

.verification-code {
  display: flex;
  .input_code {
    flex: 1;
  }
}
