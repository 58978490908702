.withDraw_form {
  .box_card_top {
    .top_right {
      padding: 0px 60px;
      min-width: 600px;
      height: 497px;
      .withdraw_note {
        max-width: 510px;
        line-height: 2em;
      }
      .el-form-item {
        @include animation-ease(fadeIn);
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 1440px) {
  .withDraw_form {
    .box_card_top {
      .top_right {
        padding: 0px 20px;
        min-width: 450px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .withDraw_form {
    .box_card_top {
      .top_right {
        margin-top: 20px;
        min-width: 600px;
        height: 400px;
      }
    }
  }
}

@media (max-width: 768px) {
  .withDraw_form {
    .box_card_top {
      .top_right {
        min-width: 480px;
      }
    }
  }
}

@media (max-width: 550px) {
  .withDraw_form {
    .box_card_top {
      .top_right {
        min-width: 350px;
        height: 430px;
        .withdraw_note {
          font-size: 12px;
          line-height: 1.4em;
        }
      }
    }
  }
}

@media (max-width: 370px) {
  .withDraw_form {
    .box_card_top {
      .top_right {
        min-width: 220px;
        .withdraw_note {
          font-size: 10px;
          line-height: 1.2em;
        }
      }
    }
  }
}
