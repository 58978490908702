.withDraw_form {
  .box_card_top {
    .top_left {
      min-width: 600px;
      @include animation-transform;
      @include rtl-sass-value(transform, translate(300px, 0px), translate(-300px, 0px));
      padding: 0px 60px;

      .labelTitle label,
      .labelTitle .row,
      /deep/.el-form-item__label {
        font-size: 16px;
      }

      .amount {
        /deep/ {
          .el-input__inner {
            font-size: 30px;
          }
          .el-form-item__label {
            margin-bottom: 10px;
          }
        }
      }
      &.left-style {
        transform: translate(0px, 0px);
      }
    }
  }
}

.el-select-dropdown {
  .el-scrollbar {
    .el-select-dropdown__item {
      font-size: 19px;
    }
  }
}

@media (max-width: 1440px) {
  .withDraw_form {
    .box_card_top {
      .top_left {
        padding: 0px 20px;
        min-width: 350px;

        &.left-style {
          transform: translate(0px, 0px);
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .withDraw_form {
    .box_card_top {
      .top_left {
        min-width: 600px;
        @include rtl-sass-value(transform, translate(0px, 0px), translate(0px, 0px));
      }
    }
  }
}

@media (max-width: 768px) {
  .withDraw_form {
    .box_card_top {
      .top_left {
        min-width: 480px;
      }
    }
  }
}

@media (max-width: 550px) {
  .withDraw_form {
    .box_card_top {
      .top_left {
        min-width: 350px;
      }
    }
  }
}

@media (max-width: 370px) {
  .withDraw_form {
    .box_card_top {
      .top_left {
        min-width: 220px;
      }
    }
  }
}
