.withDraw_form {
  .box_card_bottom {
    padding: 48px 32px 32px;

    .totalAmount {
      @include rtl-sass-value(text-align, right, left);
    }

    .title {
      margin-bottom: 30px;
    }

    .availableTypes {
      margin-top: 30px;
    }
    /deep/.form_bottom_row {
      .img_row {
        text-align: center;

        img {
          display: block;

          &.small {
            max-width: 100px;
          }
        }
      }

      .form_row {
        width: 100%;
        max-width: calc(100% - 280px);

        .labelTitle {
          margin-bottom: 10px;
        }
      }

      .form_list {
        display: grid;
        grid-template-columns: 48% 48%;
        column-gap: 4%;
      }

      .alert_info {
        font-size: 16px;
        padding-top: 10px;
        padding-bottom: 36px;
        color: #e91545;
      }

      .checkbox_wrapper {
        margin: 0 0 30px;
      }
    }

    /deep/ {
      #hongkongForm {
        .rate {
          position: relative;
          top: -40px;
        }
      }
      #netellerForm {
        .el-form-item {
          margin-bottom: 15px;
        }
      }

      #fasapayForm {
        .el-form-item {
          margin-bottom: 15px;
        }
        .form_list:first-child {
          margin-bottom: 25px;
        }
      }

      #skrillForm {
        .el-form-item {
          margin-bottom: 15px;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .box_card_bottom /deep/.form_bottom_row {
    .img_row,
    .form_row {
      float: none;
    }

    .img_row {
      margin-bottom: 50px;

      img {
        margin: 0 auto;
      }
    }

    .form_row {
      max-width: 100%;
    }

    .form_list {
      grid-template-columns: 47.5% 47.5%;
      column-gap: 5%;
    }
  }
}

@media (max-width: 550px) {
  .box_card_bottom /deep/ {
    padding: 30px 24px;
    .form_bottom_row .form_list {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));

      .int_p {
        margin-bottom: 24px;
      }
    }
  }
}
