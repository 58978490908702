
              @import "@/assets/css/variables/variables.scss";
            

.form_row {
  ul {
    li {
      p.tip {
        top: -30px;
        position: relative;
        margin-bottom: 15px;
      }
    }
  }
}
